<template>
  <div>
    <Echart
      id="centreLeft2Chart"
      ref="centreLeft2ChartRef"
      :options="options"
      @penetrate="$emit('penetrate', $event)"
      height="920px"
      width="1026px"></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
import { mapPoint } from '@/api/test'

export default {
  data() {
    return {
      options: {},
      geoCoordMap1: [
        // {
        //   name: '111',
        //   value: [123.429092, 48.796768, true],
        //   pointAddress: 'kdkdkdkk',
        //   id: 1
        // },
        // {
        //   name: '222',
        //   value: [119.429092, 48.796768, false],
        //   pointAddress: 'kdkdkdkk',
        //   id: 1
        // }
      ]
    }
  },
  components: {
    Echart
  },
  inject: ['isShow'],
  props: {
    cdata: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    geoCoordMap1: {
      handler() {
        // alert(this.isShow)
        console.log(this.geoCoordMap1, '00000000000001111111111111')
        // console.log(newData)
        // 设置点的位置(经纬度)

        // let geoCoordMap1 = [
        //   // {
        //   //   name: '沈阳',
        //   //   value: [123.429092, 48.796768, true]
        //   // },
        //   // {
        //   //   name: '吉林',
        //   //   value: [123.429092, 52.796768, false]
        //   // }
        // ]

        // const geoCoordMap = {
        //   沈阳: [123.429092, 41.796768, 20],
        //   长春: [125.324501, 43.886841, 20],
        //   哈尔滨: [126.642464, 45.756966, 20],
        //   北京: [116.405289, 39.904987, 20],
        //   天津: [117.190186, 39.125595, 20],
        //   呼和浩特: [111.75199, 40.84149, 20],
        //   银川: [106.23248, 38.48644, 20],
        //   太原: [112.549248, 37.857014, 20],
        //   石家庄: [114.502464, 38.045475],
        //   济南: [117.000923, 36.675808],
        //   郑州: [113.665413, 34.757977],
        //   西安: [108.948021, 34.263161],
        //   武汉: [114.298569, 30.584354],
        //   南京: [118.76741, 32.041546],
        //   合肥: [117.283043, 31.861191],
        //   上海: [121.472641, 31.231707],
        //   长沙: [112.982277, 28.19409],
        //   南昌: [115.892151, 28.676493],
        //   杭州: [120.15358, 30.287458],
        //   福州: [119.306236, 26.075302],
        //   广州: [113.28064, 23.125177],
        //   台北: [121.520076, 25.030724],
        //   海口: [110.19989, 20.04422],
        //   南宁: [108.320007, 22.82402],
        //   重庆: [106.504959, 29.533155],
        //   昆明: [102.71225, 25.040609],
        //   贵阳: [106.713478, 26.578342],
        //   成都: [104.065735, 30.659462],
        //   兰州: [103.83417, 36.06138],
        //   西宁: [101.77782, 36.61729],
        //   拉萨: [91.1145, 29.64415],
        //   乌鲁木齐: [87.61688, 43.82663],
        //   香港: [114.16546, 22.27534],
        //   澳门: [113.54913, 22.19875]
        // }
        // let seriesData = [
        //   { name: '沈阳' },
        //   { name: '长春' },
        //   { name: '哈尔滨' },
        //   { name: '北京' },
        //   { name: '天津' },
        //   { name: '呼和浩特' },
        //   { name: '银川' },
        //   { name: '太原' },
        //   { name: '石家庄' },
        //   { name: '济南' },
        //   { name: '郑州' },
        //   { name: '西安' },
        //   { name: '武汉' },
        //   { name: '南京' },
        //   { name: '合肥' },
        //   { name: '上海' },
        //   { name: '长沙' },
        //   { name: '南昌' },
        //   { name: '杭州' },
        //   { name: '福州' },
        //   { name: '广州' },
        //   { name: '台北' },
        //   { name: '海口' },
        //   { name: '南宁' },
        //   { name: '重庆' },
        //   { name: '昆明' },
        //   { name: '贵阳' },
        //   { name: '成都' },
        //   { name: '兰州' },
        //   { name: '西宁' },
        //   { name: '拉萨' },
        //   { name: '乌鲁木齐' },
        //   { name: '香港' },
        //   { name: '澳门' }
        // ]
        // let convertData = function (data) {
        //   let scatterData = []
        //   for (var i = 0; i < data.length; i++) {
        //     var geoCoord = geoCoordMap[data[i].name]
        //     if (geoCoord) {
        //       scatterData.push({
        //         name: data[i].name,
        //         value: geoCoord.concat(data[i].value)
        //       })
        //     }
        //   }
        //   // console.log(scatterData, '1')
        //   return scatterData
        // }
        const linesCoord = []

        if (this.isShow) {
          let chinaGeoCoordMap = {}
          this.geoCoordMap1.forEach((e, i) => {
            chinaGeoCoordMap[i] = e.value.slice(0, -1)
          })
          // console.log(chinaGeoCoordMap, 'rttttttt')
          // 散点
          const chinaDatas = []
          const mapObject = { name: '', value: null }
          // 飞线
          const lineObject = { coords: [[117.29, 32.0581]] }
          // const linesCoord = []
          for (const key in chinaGeoCoordMap) {
            mapObject.name = key
            mapObject.value = chinaGeoCoordMap[key]
            chinaDatas.push(JSON.parse(JSON.stringify(mapObject)))
            if (key !== '合肥') {
              lineObject.coords[1] = chinaGeoCoordMap[key]
              linesCoord.push(JSON.parse(JSON.stringify(lineObject)))
            }
          }
        }

        let img = document.createElement('img')
        img.src = require('@/assets/images/map11.png')
        this.options = {
          showLegendSymbol: true,
          tooltip: {
            trigger: 'item',
            textStyle: {
              fontSize: 14,
              lineHeight: 22
            },
            position: (point) => {
              // 固定在顶部
              return [point[0] + 50, point[1] - 20]
            }
            // 如果需要自定义 tooltip样式，需要使用formatter
            /*
              formatter: params => {
                return `<div style=""> ... </div>`
              }
            */
          },
          visualMap: {
            min: 0,
            max: 10,
            show: false,
            seriesIndex: 0,
            // 颜色
            inRange: {
              color: ['rgba(41,166,206, .5)', 'rgba(69,117,245, .9)']
            }
          },
          // 底部背景
          geo: {
            show: true,
            aspectScale: 0.8, //长宽比
            zoom: 1.2,
            mapType: 'China', // 自定义扩展图表类型
            top: '21.8%',
            left: '10%',
            map: 'China',
            roam: false,
            // zlevel: 10,
            // transform: {
            //   rotateX: 300 // 设置地图倾斜角度
            // },
            itemStyle: {
              normal: {
                // areaColor: '#0065f1',
                areaColor: 'rgba(0,0,0,0)',
                // areaColor: {
                //   type: 'pattern',
                //   repeat: 'repeat-x',
                //   image: img
                // },
                // zIndex: -1,
                borderColor: '#419af1', //map描边
                shadowColor: '#0065f1', //map阴影
                borderWidth: 1,
                shadowBlur: 0,
                shadowOffsetX: 2,
                shadowOffsetY: 7
              },
              emphasis: {
                areaColor: 'rgba(0,0,0,0)'
              }
            }
          },

          series: [
            {
              name: '相关指数',
              type: 'map',
              aspectScale: 0.8, //长宽比
              zoom: 1.2,
              mapType: 'China', // 自定义扩展图表类型
              top: '20.5%',
              left: '10%',
              // ayoutCenter: ['90%', '30%'],
              // layoutSize: 500,

              // geoIndex: 10,
              // zlevel: 10,
              // transform: {
              //   rotateX: 300 // 设置地图倾斜角度
              // },
              itemStyle: {
                normal: {
                  // color: 'red',
                  areaColor: {
                    type: 'pattern',
                    // repeat: '20px 50px',
                    image: img
                  },
                  // areaColor: 'rgba(19,54,162, .001)',
                  borderColor: '#1cbdf5',
                  borderWidth: 2
                  // shadowBlur: 10,
                  // shadowColor: 'blue'
                },
                emphasis: {
                  areaColor: '#4f7fff',
                  borderColor: 'rgba(179,234,252,.6)',
                  borderWidth: 3,
                  shadowBlur: 10,
                  shadowColor: '#00f2fc'
                }
              },
              label: {
                formatter: (params) => `${params.name}`,
                show: true,
                position: 'insideRight',
                textStyle: {
                  fontSize: 12,
                  color: '#efefef'
                },
                emphasis: {
                  textStyle: {
                    color: '#fff'
                  }
                }
              }
              // data: newData
            },
            {
              type: 'effectScatter',
              coordinateSystem: 'geo',
              symbolSize: 7,
              effectType: 'ripple',
              legendHoverLink: false,
              showEffectOn: 'render',
              rippleEffect: {
                period: 4,
                scale: 0,
                brushType: 'stroke'
              },
              zlevel: 1,
              itemStyle: {
                normal: {
                  color: '#99FBFE',
                  shadowBlur: 5,
                  shadowColor: '#fff'
                }
              }
              // data: convertData(seriesData)
            },
            {
              type: 'scatter',
              coordinateSystem: 'geo',
              // symbol: 'image://' + require('../../../../assets/zuobiao.svg'),
              symbol: (v, p) => {
                console.log(v, 'v')
                console.log(p, 'p')
                if (v[2]) {
                  return (
                    'image://' + require('../../../../assets/zuobiao_green.svg')
                  )
                } else {
                  return (
                    'image://' + require('../../../../assets/zuobiao_red.svg')
                  )
                }
              },
              rippleEffect: {
                period: 5,
                scale: 2.5,
                brushType: 'stroke'
              },
              zlevel: 1,
              itemStyle: {
                normal: {
                  color: 'rgba(0,242,252,.6)',
                  // shadowBlur: 5,
                  shadowColor: '#fff'
                }
              },

              symbolSize: [50, 50],
              symbolOffset: [0, 8],
              data: this.geoCoordMap1
            },
            {
              type: 'lines',
              coordinateSystem: 'geo',
              zlevel: 1,
              effect: {
                show: true,
                period: 7,
                trailLength: 0,
                symbol: 'arrow',
                color: '#FFA500',
                symbolSize: 12
              },
              lineStyle: {
                normal: {
                  width: 2,
                  opacity: 0.6,
                  curveness: 0.2,
                  color: '#FFB800'
                }
              },
              data: linesCoord
            }
          ]
        }
        // 重新选择区域
        // this.handleMapRandomSelect()
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.getMapPoint()
  },
  methods: {
    getMapPoint() {
      mapPoint().then((res) => {
        // console.log(res, '888888888888888999999999999999999')
        this.geoCoordMap1 = res.data.data.map((e) => {
          return {
            name: e.pointName,
            value: [e.longitude, e.latitude, e.type == '1' ? false : true],
            pointAddress: e.pointAddress,
            id: e.id
          }
        })
      })
    }

    // penetrate(obj) {
    //   this.$emit('penetrate', obj)
    // }
    // 开启定时器
    // startInterval() {
    //   const _self = this
    //   // 应通过接口获取配置时间，暂时写死5s
    //   const time = 2000
    //   if (this.intervalId !== null) {
    //     clearInterval(this.intervalId)
    //   }
    //   this.intervalId = setInterval(() => {
    //     _self.reSelectMapRandomArea()
    //   }, time)
    // },
    // 重新随机选中地图区域
    // reSelectMapRandomArea() {
    //   const length = 9
    //   this.$nextTick(() => {
    //     try {
    //       const map = this.$refs.centreLeft2ChartRef.chart
    //       let index = Math.floor(Math.random() * length)
    //       while (index === this.preSelectMapIndex || index >= length) {
    //         index = Math.floor(Math.random() * length)
    //       }
    //       map.dispatchAction({
    //         type: 'mapUnSelect',
    //         seriesIndex: 0,
    //         dataIndex: this.preSelectMapIndex
    //       })
    //       map.dispatchAction({
    //         type: 'showTip',
    //         seriesIndex: 0,
    //         dataIndex: index
    //       })
    //       map.dispatchAction({
    //         type: 'mapSelect',
    //         seriesIndex: 0,
    //         dataIndex: index
    //       })
    //       this.preSelectMapIndex = index
    //     } catch (error) {
    //       console.log(error)
    //     }
    //   })
    // },
    // handleMapRandomSelect() {
    //   this.$nextTick(() => {
    //     try {
    //       const map = this.$refs.centreLeft2ChartRef.chart
    //       const _self = this
    //       setTimeout(() => {
    //         _self.reSelectMapRandomArea()
    //       }, 0)
    //       // 移入区域，清除定时器、取消之前选中并选中当前
    //       map.on('mouseover', function (params) {
    //         clearInterval(_self.intervalId)
    //         map.dispatchAction({
    //           type: 'mapUnSelect',
    //           seriesIndex: 0,
    //           dataIndex: _self.preSelectMapIndex
    //         })
    //         map.dispatchAction({
    //           type: 'mapSelect',
    //           seriesIndex: 0,
    //           dataIndex: params.dataIndex
    //         })
    //         _self.preSelectMapIndex = params.dataIndex
    //       })
    //       // 移出区域重新随机选中地图区域，并开启定时器
    //       map.on('globalout', function () {
    //         _self.reSelectMapRandomArea()
    //         _self.startInterval()
    //       })
    //       this.startInterval()
    //     } catch (error) {
    //       console.log(error)
    //     }
    //   })
    // }
  }
}
</script>
