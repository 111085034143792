<template>
  <div class="pie-content">
    <div class="pie-top">
      <Echart :options="options" id="pie" height="350px" width="100%"></Echart>
      <div class="total">
        <div>{{ total || 0 }}</div>
        <div><slot></slot></div>
      </div>
    </div>

    <!-- <div class="detail-list">
      <div
        class="detail-list-one"
        v-for="item in pieData.value"
        :key="item.name">
        {{ item.value }}<slot></slot>
      </div>
    </div> -->
  </div>
</template>

<script>
import Echart from '@/common/echart'
import * as echarts from 'echarts'

export default {
  data() {
    return {
      options: {},
      total: null
    }
  },
  components: {
    Echart
  },
  props: {
    pieData: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    pieData: {
      handler(newData) {
        this.total = null
        this.pieData.value?.forEach((e) => {
          this.total += Number(e?.value)
        })
        // console.log(newData, '33333333333333333333333333')
        // console.log(newData, 'pppppppppppppppppppppp')
        // getNum:{
        //     //   console.log(name)
        //     //   let singleData = newData.value.filter(function (item) {
        //     //     return item.name == name
        //     //   })
        //     //   return name + '' + singleData[0].value + ''
        // }
        // let getNum = () => {}
        this.options = {
          tooltip: {
            trigger: 'item',
            formatter: '{b} : {c} ({d}%)'
          },
          legend: {
            bottom: '0%',
            // left: 'center',
            // top: '50%',
            width: 400,
            itemHeight: 8,
            itemWidth: 8,
            left: '10%',
            // itemGap: 25, //图例之间的间距
            x: 'center', //可设定图例在左、右、居中
            // y: 'center', //可设定图例在上、下、居中
            // padding: [0, 500, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
            // formatter: function (name) {
            //   console.log(name)
            //   let singleData = newData.value.filter(function (item) {
            //     return item.name == name
            //   })
            //   return name + '\t\t\t' + singleData[0].value + ''
            // },
            formatter: (params) => {
              // console.log(params)
              let singleData = newData?.value.filter(function (item) {
                return item.name == params
              })
              return `{a|${params}}` + singleData[0].value + newData.unit
            },
            // formatter: ['{a|{name}}', ``].join(
            //   '\t'
            // ),
            textStyle: {
              rich: {
                a: {
                  width: 105,
                  fontSize: 12,
                  lineHeight: 18
                }
              }
            }
          },
          series: [
            {
              name: newData.name,
              type: 'pie',
              radius: [40, 90],
              center: ['50%', '37%'],
              // center: '50%',
              roseType: 'radius',
              // itemStyle: {
              //   borderRadius: 5
              // },
              label: {
                show: false
              },
              itemStyle: {
                borderRadius: 5,
                normal: {
                  color: (list) => {
                    // 注意 ！！！！！ 这里的数组一定要和实际的类目长度相等或大于，不然会缺少颜色报错
                    var colorList = [
                      {
                        colorStart: '#2996f6',
                        colorEnd: '#24e3d0'
                      },
                      {
                        colorStart: '#3f84f1',
                        colorEnd: '#a130d8'
                      },
                      {
                        colorStart: '#407bff',
                        colorEnd: '#3f7aff'
                      },
                      {
                        colorStart: '#FFC069',
                        colorEnd: '#fadfba'
                      },
                      {
                        colorStart: '#ff5e46',
                        colorEnd: '#fcc457'
                      },
                      {
                        colorStart: '#68fff6',
                        colorEnd: '#9cfff9'
                      }
                    ]
                    return new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                      {
                        //左、下、右、上
                        offset: 0,
                        color: colorList[list.dataIndex]['colorStart']
                      },
                      {
                        offset: 1,
                        color: colorList[list.dataIndex]['colorEnd']
                      }
                    ])
                  }
                }
              },
              data: newData.value
            }
          ]
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    // this.getEquipTotal()
  }
}
</script>
<style scoped lang="scss">
.pie-content {
  position: relative;
  .total {
    color: #fff;
    position: absolute;
    top: 111px;
    left: 50%;
    transform: translate(-50%);
    text-align: center;
  }
  .pie-top {
    position: relative;
    z-index: 2;
  }
  .detail-list {
    display: flex;
    flex-wrap: wrap;
    color: #fff;
    position: absolute;
    bottom: 5px;
    left: 166px;
    z-index: 10;
    .detail-list-one {
      width: 68px;
      margin-top: 10px;
    }
    .detail-list-one:nth-child(2n - 1) {
      margin-right: 75px;
    }
  }
}
</style>
