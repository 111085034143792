<template>
  <div>
    <Chart :cdata="cdata" @penetrate="$emit('penetrate', $event)" />
  </div>
</template>

<script>
import Chart from './chart.vue'
export default {
  data() {
    return {
      cdata: [
        { name: '北京', value: 17 },
        { name: '天津', value: 12 },
        { name: '上海', value: 15 },
        { name: '重庆', value: 15 },
        { name: '河北', value: 15 },
        { name: '河南', value: 15 },
        { name: '云南', value: 5 },
        { name: '辽宁', value: 15 },
        { name: '黑龙江', value: 15 },
        { name: '湖南', value: 2 },
        { name: '安徽', value: 15 },
        { name: '山东', value: 15 },
        { name: '新疆', value: 3 },
        { name: '江苏', value: 3 },
        { name: '浙江', value: 9 },
        { name: '江西', value: 15 },
        { name: '湖北', value: 4 },
        { name: '广西', value: 4 },
        { name: '甘肃', value: 10 },
        { name: '山西', value: 15 },
        { name: '内蒙古', value: 15 },
        { name: '陕西', value: 9 },
        { name: '吉林', value: 8 },
        { name: '福建', value: 9 },
        { name: '贵州', value: 9 },
        { name: '广东', value: 8 },
        { name: '青海', value: 3 },
        { name: '西藏', value: 9 },
        { name: '四川', value: 0 },
        { name: '宁夏', value: 15 },
        { name: '海南', value: 7 },
        { name: '台湾', value: 4 },
        { name: '香港', value: 4 },
        { name: '澳门', value: 3 }
      ]
    }
  },
  components: {
    Chart
  },
  mounted() {
    // alert(this.isOpen, 'llll')
  },
  methods: {
    // penetrate(obj) {
    //   console.log('22')
    //   this.$emit('map', obj)
    // }
  }
}
</script>

<style lang="scss" scoped></style>
