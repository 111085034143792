<template>
  <div id="centerLeft1">
    <div class="bg">
      <div class="d-flex jc-center">
        <CenterLeft2Chart @penetrate="penetrate" />
      </div>
      <el-dialog
        id="ppp"
        :title="dataValue.pointName"
        custom-class="dialog-list"
        :visible.sync="dialogVisible"
        width="92%"
        append-to-body>
        <div class="location">
          <img src="../assets/zuobiaoGray.svg" alt="" />
          <div>
            {{ dataValue.pointAddress }}
          </div>
        </div>
        <div class="table-list">
          <div class="table-top">
            <div class="table-one">
              <div class="table-name">单位</div>
              <div class="table-datail">
                {{ dataValue.pointName || '暂无' }}
              </div>
            </div>
            <div class="table-one">
              <div class="table-name">项目经理</div>
              <div class="table-datail">{{ dataValue.leader || '暂无' }}</div>
            </div>
            <div class="table-one">
              <div class="table-name">联系方式</div>
              <div class="table-datail">{{ dataValue.phone || '暂无' }}</div>
            </div>
          </div>
          <!-- <div class="table-top table-bottom">
            <div class="table-one">
              <div class="table-name">职员</div>
              <div class="table-datail">{{ '张无忌' }}</div>
            </div>
            <div class="table-one">
              <div class="table-name">职工</div>
              <div class="table-datail">{{ '张无忌' }}</div>
            </div>
            <div class="table-one">
              <div class="table-name">大学生</div>
              <div class="table-datail">{{ '张无忌' }}</div>
            </div>
          </div> -->
        </div>
        <div class="info-list">
          <el-row :gutter="16">
            <el-col :lg="8">
              <!-- <div>hhh</div> -->
              <div class="list-one">
                <div class="title">
                  <div class="title-left"></div>
                  <div class="title-name">{{ '人员信息' }}</div>
                </div>
                <!--  -->
                <div class="pie" v-if="dataValue.positions.length > 0">
                  <bar :barData="dataValue.positions"></bar>
                </div>
                <div class="empty" v-else>暂无数据</div>
              </div>
            </el-col>
            <el-col
              :lg="8"
              v-for="(item, index) in bottomList"
              :key="item.name">
              <div class="list-one">
                <div class="title">
                  <div class="title-left"></div>
                  <div class="title-name">{{ item.name }}</div>
                </div>
                <!--  -->
                <div class="pie" v-if="item.value.length > 0">
                  <thepie :pieData="item">{{
                    index == 0 ? '台' : '套'
                  }}</thepie>
                </div>
                <div class="empty" v-else>暂无数据</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import CenterLeft2Chart from '@/components/echart/centerLeft/centerLeft2Chart'
import thepie from '@/common/pie'
import bar from '@/common/bar'
import { mapPointDetail } from '../api/test'
import { anUserStatistic } from '../api/test'

export default {
  components: {
    CenterLeft2Chart,
    thepie,
    bar
  },
  data() {
    return {
      dataValue: {
        positions: [],
        pointName: '石台项目部'
      },
      dialogVisible: false,
      tableData: [],
      id: null,
      bottomList: [],
      barData: []
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      default: true
    }
  },
  mounted() {},
  methods: {
    penetrate(obj) {
      if (!this.isOpen) return
      this.getAnUserStatistic(obj.value)
      this.dialogVisible = true
      mapPointDetail({ deptId: obj.value }).then((res) => {
        this.dataValue = res.data.data
        this.bottomList = [
          {
            name: '装备详情',
            value: res.data.data.equipments.map((re) => {
              return {
                name: re.name,
                value: re.num
              }
            }),
            unit: '台'
          },
          {
            name: '物资详情',
            value: res.data.data.materials.map((re) => {
              return {
                name: re.name,
                value: re.num
              }
            }),
            unit: '套'
          }
        ]
        console.log(this.bottomList, 'pqdjjcja')
      })
    },
    getAnUserStatistic(id) {
      anUserStatistic({ deptId: id }).then((res) => {
        if (res.data.data) {
          this.tableData = [res.data.data]
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#centerLeft1 {
  $box-width: 1045px;
  $box-height: 950px;
  padding: 16px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  // background-image: url('../assets/images/chinaMap3.png');
  // background-size: contain;
  background-size: 97% 83%;
  background-repeat: no-repeat;
  background-position: center;
  .bg {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .dv-dec-1 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }
  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;
    .active-ring-name {
      padding-top: 10px;
    }
  }
}
::v-deep .dialog-zb {
  background-image: url('../assets/tcbg2.png');
  background-size: 100% 100%;
  background-color: #0e2241;
  width: 1300px !important;
  height: 700px !important;
  // width: 68% !important;
  // height: 74% !important;
  margin-top: 10vh !important;

  .el-dialog__title {
    color: #fff;
    font-size: 24px;
  }
  .el-dialog__header {
    // padding: 16px 20px 10px;
    padding-top: 53px;
    padding-left: 40px;
  }
  .el-dialog__body {
    padding: 38px 38px 25px;
    .location {
      display: flex;
      color: #fff;
      font-size: 18px;
      position: absolute;
      top: 54px;
      right: 65px;
      & > img {
        margin-right: 8px;
      }
    }
    .table-list {
      width: 100%;
      height: 44px;
      border: 1px solid rgba(47, 75, 134, 1);
      // color: ;
      color: rgba(217, 235, 255, 1);

      // background-color: #fff;
      .table-top {
        display: flex;
        height: 43px;
        border-bottom: 1px solid rgba(47, 75, 134, 1);
        .table-one {
          width: 33%;
          display: flex;
          line-height: 43px;
          border-right: 1px solid rgba(47, 75, 134, 1);
          .table-name {
            width: 164px;
            padding-left: 10px;
            color: rgba(145, 168, 208, 1);
            border-right: 1px solid rgba(47, 75, 134, 1);
          }
          .table-datail {
            padding-left: 10px;
          }
        }
        .table-one:nth-last-child(1) {
          border-right: 0;
        }
      }
      .table-bottom {
        border-bottom: 0;
      }
    }
    .info-list {
      // display: flex;
      margin-top: 15px;
      .list-one {
        // width: 378px;
        height: 421px;
        margin-right: 15px;
        border-radius: 8px;
        // background-image: linear-gradient(bottom, #13264e, #afe4fe);
        background-color: #13264e;
        .title {
          display: flex;
          align-items: center;
          padding-top: 30px;
          margin-left: 18px;
          color: #fff;
          font-size: 18px;
          .title-left {
            width: 3px;
            height: 20px;
            margin-right: 12px;
            background-color: rgba(28, 90, 226, 1);
          }
        }
        .pie {
        }
      }
      .list-one:nth-last-child(1) {
        margin-right: 0;
      }
      .list-one:nth-child(1) {
        // width: 400px;
      }
      .empty {
        text-align: center;
        line-height: 350px;
        color: #fff;
      }
    }
  }
}
.bottom-table {
  margin-top: 14px;
  border-top: 2px solid #6187e4;
  // width: 400px;
  height: 356px;
  overflow: auto;
  // overflow-x: hidden;
  // overflow-y: hidden;
  ::v-deep .el-table .el-table__cell {
    padding: 7px 0 !important;
  }
  ::v-deep .el-table,
  ::v-deep .el-table__expanded-cell {
    background-color: transparent;
  }
  /* 表格内背景颜色 */
  ::v-deep .el-table th,
  ::v-deep .el-table tr,
  ::v-deep .el-table td {
    color: rgb(183, 210, 241);
    background-color: transparent;
  }
  // 背景色
  ::v-deep .el-table tr {
    background-color: #131e34;
    // background-color: red;
  }
  // 表格分开
  ::v-deep .el-table__body {
    //-webkit-border-horizontal-spacing: 13px;  // 水平间距
    -webkit-border-vertical-spacing: 10px; // 垂直间距
  }
  // 隔行变色
  ::v-deep .el-table--striped {
    .el-table__body tr.el-table__row--striped td.el-table__cell {
      background-color: #0d172a;
    }
  }
  ::v-deep .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: 1px solid #243a64;
  }
  // hover
  ::v-deep
    .el-table--enable-row-hover
    .el-table__body
    tr:hover
    > td.el-table__cell {
    background-color: unset;
  }
  // 箭头
  ::v-deep .el-icon-arrow-right:before {
    color: rgb(183, 210, 241);
  }
  // header   border-bottom
  ::v-deep .el-table th.el-table__cell.is-leaf {
    border-bottom: 0;
  }
  //最底部横线
  ::v-deep .el-table::before {
    height: 0;
  }
}
.bottom-table::-webkit-scrollbar {
  width: 6px;
  height: 50px;
  background-color: rgb(183, 210, 241);
}
.bottom-table::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #00aaff;
}
::v-deep .dialog-list {
  background-color: #0e2241;
  width: 75% !important;
  height: 645px !important;
  margin-top: 10vh !important;
  .el-dialog__title {
    color: #fff;
  }
  .el-dialog__header {
    padding: 0 0 0 58px;
    height: 50px;
    line-height: 50px;

    background-image: url('../assets/images/list-top.svg');
    background-repeat: no-repeat;
    background-position: 20px 10px;
    // background-size: 100% 100%;
    background-color: #2a4e93;
  }
  .el-dialog__body {
    padding: 20px 38px;
    height: calc(100% - 55px);
    overflow: auto;
    .el-form-item--small .el-form-item__label {
      color: rgb(183, 210, 241);
    }
    .el-table .el-table__cell.gutter {
      background: #3c598c !important;
    }
  }
}
.location {
  display: flex;
  color: #fff;
  font-size: 18px;
  position: absolute;
  top: 17px;
  right: 65px;
  & > img {
    margin-right: 8px;
  }
}
.table-list {
  width: 100%;
  height: 44px;
  border: 1px solid rgba(47, 75, 134, 1);
  // color: ;
  color: rgba(217, 235, 255, 1);
  margin-top: 20px;
  // background-color: #fff;
  .table-top {
    display: flex;
    height: 43px;
    border-bottom: 1px solid rgba(47, 75, 134, 1);
    .table-one {
      width: 33%;
      display: flex;
      line-height: 43px;
      border-right: 1px solid rgba(47, 75, 134, 1);
      .table-name {
        width: 164px;
        padding-left: 10px;
        color: rgba(145, 168, 208, 1);
        border-right: 1px solid rgba(47, 75, 134, 1);
      }
      .table-datail {
        padding-left: 10px;
      }
    }
    .table-one:nth-last-child(1) {
      border-right: 0;
    }
  }
  .table-bottom {
    border-bottom: 0;
  }
}
.info-list {
  // display: flex;
  margin-top: 40px;
  .list-one {
    // width: 378px;
    height: 421px;
    margin-right: 15px;
    border-radius: 8px;
    // background-image: linear-gradient(bottom, #13264e, #afe4fe);
    background-color: #13264e;
    .title {
      display: flex;
      align-items: center;
      padding-top: 30px;
      margin-left: 18px;
      color: #fff;
      font-size: 18px;
      .title-left {
        width: 3px;
        height: 20px;
        margin-right: 12px;
        background-color: rgba(28, 90, 226, 1);
      }
    }
    .pie {
    }
  }
  .list-one:nth-last-child(1) {
    margin-right: 0;
  }
  .list-one:nth-child(1) {
    // width: 400px;
  }
  .empty {
    text-align: center;
    line-height: 350px;
    color: #fff;
  }
}
</style>
