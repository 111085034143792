<template>
  <div class="pie-content">
    <Echart :options="options" id="bar" height="350px" width="100%"></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
// import * as echarts from 'echarts'

export default {
  data() {
    return {
      options: {}
      // total: null
    }
  },
  components: {
    Echart
  },
  props: {
    barData: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    barData: {
      handler(newData) {
        let heng = newData.map((e) => {
          return e.positionName
        })
        let value = newData.map((e) => {
          return e.positionCount
        })
        console.log(newData, '-------------------------=========')

        this.options = {
          grid: {
            left: '3%',
            right: '4%',
            bottom: '1%',
            containLabel: true
          },
          tooltip: {
            trigger: 'item',
            formatter: '{b} : {c}人'
          },
          xAxis: {
            type: 'category',
            data: heng
          },
          yAxis: {
            type: 'value',
            name: '单位(人)'
          },
          series: [
            {
              data: value,
              type: 'bar',
              label: {
                show: true, //开启显示
                position: 'top', //在上方显示
                formatter: '{c}', //显示百分号
                textStyle: {
                  //数值样式
                  color: '#fff', //字体颜色
                  fontSize: 15 //字体大小
                }
              },

              itemStyle: {
                // 使用方法二的写法
                color: {
                  type: 'linear',
                  x: 0, //右
                  y: 0, //下
                  x2: 0, //左
                  y2: 1, //上
                  colorStops: [
                    {
                      offset: 0,
                      color: '#2996f6' // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: '#24e3d0' // 100% 处的颜色
                    }
                  ]
                }
              }
            }
          ]
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    // this.getEquipTotal()
  }
}
</script>
<style scoped lang="scss">
.pie-content {
  position: relative;
  .total {
    color: #fff;
    position: absolute;
    top: 111px;
    left: 50%;
    transform: translate(-50%);
    text-align: center;
  }
  .pie-top {
    position: relative;
    z-index: 2;
  }
  .detail-list {
    display: flex;
    flex-wrap: wrap;
    color: #fff;
    position: absolute;
    bottom: 5px;
    left: 166px;
    z-index: 10;
    .detail-list-one {
      width: 68px;
      margin-top: 10px;
    }
    .detail-list-one:nth-child(2n - 1) {
      margin-right: 75px;
    }
  }
}
</style>
